#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
    #app {
        height: 100%;
      }
      html,
      body {
        position: relative;
        height: 100%;
      }
      
      body {
        background: #eee;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #000;
        margin: 0;
        padding: 0;
      }
      
      .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
      
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
  }
  

  /* :root {
    --random_bg: url(https://source.unsplash.com/random)
  }
  
   */

  
  h1 {
    color: #bbb;
    letter-spacing: 15px;
    font-weight: 100;
    font-size: 30px;
    z-index: 1
  }
  
  /* CSS to create the blob  */
  
  .blob-wobble {
    width: 500px;
    height: 500px;
    border-radius: 41% 59% 31% 69% / 51% 72% 28% 49%;
    animation: wobble 10s ease-in-out alternate infinite;
    box-shadow: 2px 14px 28px -10px rgba(0, 0, 0, 0.7);
    z-index: 2;
    overflow: hidden; /* Add this to hide the image overflow */
  }
  
  .blob-wobble img {
    width: 110%; /* Set image width to 100% of parent */
    height: 100%; /* Set image height to 100% of parent */
    object-fit: cover; /* Scale the image to cover the parent without distorting */
  }
  
  
  /* CSS to animate the blob */
  
  @keyframes wobble {
    
      25% {
      border-radius: 41% 59% 74% 26% / 51% 31% 69% 49% ;
      transform: scale(0.9) rotate(-5deg);
    }
    
    50% {
      border-radius: 23% 77% 73% 27% / 14% 21% 79% 86%  ;
      transform: scale(0.8) rotate(6deg);
    }
    
    100% {
      border-radius: 75% 25% 75% 25% / 45% 26% 74% 55%  ;
      transform: scale(0.95) rotate(-9deg);
    }
  }


  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,900');
@import url('https://fonts.googleapis.com/css?family=Raleway');

body
{
	margin:0;
	padding:0;
	background:#fff;
	font-family: 'Nunito Sans', sans-serif;
}

.outer
{
  margin-left: 45px;
  margin-top: -7%;
	width:96%;
	height:95vh;
  background-image: url("../assets//img/shape.png");
	background-size: auto;
}
.inner
{
	width:100%;
	height:100%;
	background:transparent;
	display:flex;
	text-align:center;
	align-items:center;
	transition:all 0.3s linear;
	z-index:0;
	background-size: cover;
	}
.inner-hover
{
    transform-origin: 75% 50%;
    transform: scale(0.85,0.85);

}

.prod-img
{
	width:70px;
	height:70px;
	margin:0px auto;
	border-radius:3px;
	transition:background 0.3s linear;
}
.prod-left
{
	width:55%;
	text-align:left;
	padding-left:75px;
}
/* .prod-right
{
	width:45%;
} */
.prod-human-img
{
	width:250px;
	height:300px;
}
.prod-head
{
	margin-left:-5px !important;
	text-align:left;
	font-weight:800;
	font-size:80pt;
	color:#6f7576;
	margin:0;
}
.prod-head-sub
{
   font-weight:400;
	font-size:25pt;
	color:#6f7576;
	margin:0 auto;
}
.prod-logo:hover .prod-img
{
	background:#fff;
	box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.09), 0 3px 2px -2px rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}

.blob {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  background: linear-gradient(
    to bottom right,
    #ff9e9e,
    #fec1c1,
    #ffffff,
    #fec1c1,
    #ff9e9e
  );
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  transform: rotate(-20deg);
}



svg {
  max-width: 70vw;
  max-height: 90vh;
}

